import {get,post,post2,put,put2,$delete} from '@/plugins/axios.js'

export default{
	
	async post(params) {
		return await post('/api/dept', params);
	},
	async del(params) {
		return await $delete('/api/dept', params);
	},
	async put(params) {
		return await put('/api/dept', params);
	},
	async get(params) {
		return await get('/api/dept', params);
	},
	async list(params) {
		return await get('/api/dept/list', params);
	},
	
}