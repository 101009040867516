<template>

  <div>
    <div class="block-header">
      <div class="row">
        <div class="col-lg-7 col-md-6 col-sm-12">
          <h2>权限管理
            <small class="text-muted"></small>
          </h2>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row clearfix">
        <div class="col-lg-12">
          <div class="card">
            <div class="body">

              <el-row>

                <el-col :span="3" :offset="20">
                  <el-button-group>
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="add">
                    </el-button>
                  </el-button-group>
                </el-col>
              </el-row>
              <br><br>


              <el-table ref="multipleTable" :data="list" style="width: 100%" border
                        @selection-change="handleSelectionChange" height="calc(100vh - 270px)" size="mini">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="序号" width="60px" align="center">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="权限名称" align="center"></el-table-column>
                <el-table-column prop="type" label="权限类型" align="center"></el-table-column>
                <el-table-column label="资源" align="center">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.values.indexOf(item.id)!=-1" v-for="(item,index) in resouces"
                            style="margin: 4px;">{{ item.name }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="部门" align="center">
                  <template v-slot="scope">
                    <el-tag v-if="scope.row.depts && scope.row.depts.indexOf(item.id)!=-1" type="warning" v-for="(item,index) in depts"
                            style="margin: 4px;">{{ item.name }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template v-slot="scope">
                    <el-button-group>
                      <el-button icon="el-icon-edit" type="primary" size="mini"
                                 @click="editor(scope.row)">编辑
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>

            </div>
          </div>
        </div>
      </div>
    </div>


    <el-dialog title="权限编辑" :visible.sync="dialogVisible" width="30%" :modal="false">

      <el-form ref="form" label-width="120px" size="mini">

        <el-row>
          <el-col :span="18">
            <el-form-item label="权限名称" required>
              <el-input v-model="current_role.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="18">
            <el-form-item label="权限类型" required>
              <el-select v-model="current_role.type" placeholder="请选择">
                <el-option label="system" value="system"></el-option>
                <el-option label="custom" value="custom"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="资源(多选)" required>
            <el-select v-model="current_role.values" placeholder="请选择" style="width: 280px;" multiple>
              <el-option v-for="(item,index) in resouces" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="部门(多选)" required>
            <el-select v-model="current_role.depts" placeholder="请选择" style="width: 280px;" multiple filterable>
              <el-option v-for="(item,index) in depts" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-row>
          <el-button type="primary" @click="submit">保存</el-button>
        </el-row>
      </el-form>

    </el-dialog>

  </div>

</template>

<script>
import deptController from "@/controller/deptController";
import roleController from "@/controller/roleController.js";
import resourceController from '@/controller/resourceController.js';

export default {

  data() {
    return {
      current_role: {
        values: []
      },
      multipleSelection: [],
      list: [],
      resouces: [],
      dialogVisible: false,
      depts: [],
    }
  },
  created() {
    this.initDepts();
    this.initResource()
    this.queryList()
  },
  methods: {
    initDepts() {
      deptController.list().then(res => {
        this.depts = res.data;
      })
    },
    initResource() {
      resourceController.list().then(res => this.resouces = res.data)
    },
    queryList() {
      roleController.list().then(res => this.list = res.data)
    },
    add(obj) {
      this.current_role = {values: []}
      this.dialogVisible = true
    },
    editor(obj) {
      this.current_role = this.deepClone(obj)
      this.dialogVisible = true;
    },
    submit() {
      let obj = this.deepClone(this.current_role)
      if (this.current_role.id) {
        roleController.put(obj).then(res => this.queryList())
      } else {
        roleController.post(obj).then(res => this.queryList())
      }

      this.dialogVisible = false;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  },


}
</script>

<style scoped>

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
